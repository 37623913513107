"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlAccountCreator = exports.LocalAccountCreator = exports.AccountCreator = void 0;
const web_1 = require("./utils/web");
/**
 * Account creator provides an interface for implementations to actually create accounts
 */
class AccountCreator {
}
exports.AccountCreator = AccountCreator;
class LocalAccountCreator extends AccountCreator {
    constructor(masterAccount, initialBalance) {
        super();
        this.masterAccount = masterAccount;
        this.initialBalance = initialBalance;
    }
    /**
     * Creates an account using a masterAccount, meaning the new account is created from an existing account
     * @param newAccountId The name of the NEAR account to be created
     * @param publicKey The public key from the masterAccount used to create this account
     * @returns {Promise<void>}
     */
    createAccount(newAccountId, publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.masterAccount.createAccount(newAccountId, publicKey, this.initialBalance);
        });
    }
}
exports.LocalAccountCreator = LocalAccountCreator;
class UrlAccountCreator extends AccountCreator {
    constructor(connection, helperUrl) {
        super();
        this.connection = connection;
        this.helperUrl = helperUrl;
    }
    /**
     * Creates an account using a helperUrl
     * This is [hosted here](https://helper.nearprotocol.com) or set up locally with the [near-contract-helper](https://github.com/nearprotocol/near-contract-helper) repository
     * @param newAccountId The name of the NEAR account to be created
     * @param publicKey The public key from the masterAccount used to create this account
     * @returns {Promise<void>}
     */
    createAccount(newAccountId, publicKey) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (0, web_1.fetchJson)(`${this.helperUrl}/account`, JSON.stringify({ newAccountId, newAccountPublicKey: publicKey.toString() }));
        });
    }
}
exports.UrlAccountCreator = UrlAccountCreator;
