'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var errors = require('./errors.js');
var shard = require('./shard.js');
var base = require('./base.js');
var memory = require('./memory.js');
var keytransform = require('./keytransform.js');
var sharding = require('./sharding.js');
var mount = require('./mount.js');
var tiered = require('./tiered.js');
var namespace = require('./namespace.js');



exports.Errors = errors;
exports.shard = shard;
exports.BaseDatastore = base.BaseDatastore;
exports.MemoryDatastore = memory.MemoryDatastore;
exports.KeyTransformDatastore = keytransform.KeyTransformDatastore;
exports.ShardingDatastore = sharding.ShardingDatastore;
exports.MountDatastore = mount.MountDatastore;
exports.TieredDatastore = tiered.TieredDatastore;
exports.NamespaceDatastore = namespace.NamespaceDatastore;
