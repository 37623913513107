'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');
var get = require('../get.js');
var put = require('../put.js');

function createRmLink({repo, preload}) {
  const get$1 = get.createGet({
    repo,
    preload
  });
  const put$1 = put.createPut({
    repo,
    preload
  });
  async function rmLink(cid, link, options = {}) {
    const node = await get$1(cid, options);
    const name = (typeof link === 'string' ? link : link.Name) || '';
    node.Links = node.Links.filter(l => l.Name !== name);
    return put$1(node, options);
  }
  return withTimeoutOption.withTimeoutOption(rmLink);
}

exports.createRmLink = createRmLink;
