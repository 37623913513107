'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createAddrs({network}) {
  async function addrs(options = {}) {
    const peers = [];
    const {libp2p} = await network.use(options);
    for await (const peer of libp2p.peerStore.getPeers()) {
      peers.push({
        id: peer.id.toB58String(),
        addrs: peer.addresses.map(mi => mi.multiaddr)
      });
    }
    return peers;
  }
  return withTimeoutOption.withTimeoutOption(addrs);
}

exports.createAddrs = createAddrs;
