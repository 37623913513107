'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var service = require('../utils/service.js');

function createStop({network, preload, ipns, repo, mfsPreload}) {
  const stop = async () => {
    await Promise.all([
      preload.stop(),
      ipns.stop(),
      mfsPreload.stop()
    ]);
    await service.Service.stop(network);
    await repo.close();
  };
  return stop;
}

exports.createStop = createStop;
