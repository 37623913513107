'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const ipfsCore = '0.14.3';
const commit = '';
const interfaceIpfsCore = '^0.154.3';

exports.commit = commit;
exports.interfaceIpfsCore = interfaceIpfsCore;
exports.ipfsCore = ipfsCore;
