'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

const Kinds = {
  Null: obj => obj === null,
  Int: obj => Number.isInteger(obj),
  Float: obj => typeof obj === 'number' && Number.isFinite(obj),
  String: obj => typeof obj === 'string',
  Bool: obj => typeof obj === 'boolean',
  Bytes: obj => obj instanceof Uint8Array,
  Link: obj => !Kinds.Null(obj) && typeof obj === 'object' && obj.asCID === obj,
  List: obj => Array.isArray(obj),
  Map: obj => !Kinds.Null(obj) && typeof obj === 'object' && obj.asCID !== obj && !Kinds.List(obj) && !Kinds.Bytes(obj)
};
const Types = {
  Int: Kinds.Int,
  'CarHeader > version': obj => Types.Int(obj),
  'CarHeader > roots (anon) > valueType (anon)': Kinds.Link,
  'CarHeader > roots (anon)': obj => Kinds.List(obj) && Array.prototype.every.call(obj, Types['CarHeader > roots (anon) > valueType (anon)']),
  'CarHeader > roots': obj => Types['CarHeader > roots (anon)'](obj),
  CarHeader: obj => {
    const keys = obj && Object.keys(obj);
    return Kinds.Map(obj) && ['version'].every(k => keys.includes(k)) && Object.entries(obj).every(([name, value]) => Types['CarHeader > ' + name] && Types['CarHeader > ' + name](value));
  }
};
const CarHeader = Types.CarHeader;

exports.CarHeader = CarHeader;
