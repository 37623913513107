'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var withTimeoutOption = require('ipfs-core-utils/with-timeout-option');

function createPut({repo, codecs, hashers, preload}) {
  async function put(dagNode, options = {}) {
    const release = options.pin ? await repo.gcLock.readLock() : null;
    try {
      const storeCodec = await codecs.getCodec(options.storeCodec || 'dag-cbor');
      if (!storeCodec) {
        throw new Error(`Unknown storeCodec ${ options.storeCodec }, please configure additional BlockCodecs for this IPFS instance`);
      }
      if (options.inputCodec) {
        if (!(dagNode instanceof Uint8Array)) {
          throw new Error('Can only inputCodec on raw bytes that can be decoded');
        }
        const inputCodec = await codecs.getCodec(options.inputCodec);
        if (!inputCodec) {
          throw new Error(`Unknown inputCodec ${ options.inputCodec }, please configure additional BlockCodecs for this IPFS instance`);
        }
        dagNode = inputCodec.decode(dagNode);
      }
      const cidVersion = options.version != null ? options.version : 1;
      const hasher = await hashers.getHasher(options.hashAlg || 'sha2-256');
      if (!hasher) {
        throw new Error(`Unknown hash algorithm ${ options.hashAlg }, please configure additional MultihashHashers for this IPFS instance`);
      }
      const buf = storeCodec.encode(dagNode);
      const hash = await hasher.digest(buf);
      const cid$1 = cid.CID.create(cidVersion, storeCodec.code, hash);
      await repo.blocks.put(cid$1, buf, { signal: options.signal });
      if (options.pin) {
        await repo.pins.pinRecursively(cid$1);
      }
      if (options.preload !== false) {
        preload(cid$1);
      }
      return cid$1;
    } finally {
      if (release) {
        release();
      }
    }
  }
  return withTimeoutOption.withTimeoutOption(put);
}

exports.createPut = createPut;
